import { defineStore } from "pinia";
import { ref, Ref } from "vue";
import { DamageType, DamageCategory } from "@/types/simTypes";

export const useDamageStore = defineStore("damage", () => {
  const damageType: Ref<DamageType | null> = ref(null);
  const damageCategory: Ref<DamageCategory | null> = ref(null);
  return {
    damageType,
    damageCategory,
  };
});
