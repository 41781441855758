<script setup lang="ts">
import { defineProps, defineEmits, ref } from "vue";
import { storeToRefs } from "pinia";
import { MediaType, MediaItem } from "@/types/mediaTypes";
import { useDamageStore } from "@/stores/damage";

interface Props {
  mediaPairs: Array<Array<MediaItem | null>>;
  comparisonViewerSelectedRowIndex: number | null;
  comparisonViewerOpen: boolean | undefined;
}

const props = defineProps<Props>();
const emit = defineEmits(["selectMediaPair"]);

const { damageCategory } = storeToRefs(useDamageStore());
const callToActionUrl = ref<string>("javascript:void(0)");

const handleImageClick = (rowIndex: number) => {
  emit("selectMediaPair", rowIndex);
};
const getSelectedImageClass = (index: number) => {
  return index == props.comparisonViewerSelectedRowIndex
    ? "selected"
    : "not-selected";
};

function capitalize(str: string): string {
  if (typeof str !== "string" || !str.length) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
</script>

<template>
  <div class="film-strip">
    <div v-if="!props.comparisonViewerOpen" class="info-images z-3">
      <div class="fixed-images left-images">
        <img class="left-info-arrow" src="../assets/infoArrow.png" />
        <div class="info info-left">
          <div>
            <span class="fw-bold">This</span> is what your home would look like
            if damaged by {{ damageCategory }}.
          </div>
        </div>
      </div>
      <div class="fixed-images right-images">
        <img class="right-info-arrow" src="../assets/infoArrow.png" />
        <div class="info info-right">
          <div>
            With our
            <span class="fw-bold">
              {{ capitalize(String(damageCategory)) }} mitigation technology,
              this</span
            >
            is what your home could look like after the
            <span class="fw-bold">same</span> disaster.
            <a :href="callToActionUrl" class="call-to-action-link"
              >Learn more!</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="media-pair"
      v-for="(mediaPair, rowIndex) in props.mediaPairs"
      :key="rowIndex"
    >
      <div :class="['pair-container', getSelectedImageClass(rowIndex)]">
        <div
          class="comparison-img-container position-relative"
          v-for="(mediaItem, mediatItemIndex) in mediaPair"
          :key="mediatItemIndex"
        >
          <div class="media-overlay-container">
            <spinner-overlay
              v-if="null === mediaItem"
              position="absolute"
              class="w-100 h-100 z-1"
            ></spinner-overlay>
            <div v-else>
              <img
                v-if="MediaType.ImageJPEG === mediaItem.type"
                class="comparison-img"
                :src="mediaItem.src"
                alt="Film strip image"
                @click="handleImageClick(rowIndex)"
              />
              <div
                v-if="MediaType.ImageAPNG === mediaItem.type"
                class="media-overlay-container"
              >
                <spinner-overlay
                  v-if="'' === mediaItem.src"
                  position="absolute"
                  class="w-100 h-100 z-1"
                  message="Video generating..."
                ></spinner-overlay>
                <div class="ratio ratio-1x1">
                  <img :src="mediaItem.src" alt="Film strip video" />
                </div>
              </div>
              <div
                v-if="MediaType.VideoMP4 === mediaItem.type"
                class="media-overlay-container"
              >
                <spinner-overlay
                  v-if="null === mediaItem.src"
                  position="absolute"
                  class="w-100 h-100 z-1"
                  message="Video generating..."
                ></spinner-overlay>
                <div class="ratio ratio-1x1">
                  <video controls muted autoplay loop>
                    <source :src="mediaItem.src" type="video/mp4" />
                    Your browser does not support videos.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.info-images {
  position: fixed;
  display: flex;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: $app-max-width;
  height: calc(117px + 16px);
  z-index: 1;
  @media (prefers-color-scheme: light) {
    background-color: $light-bg;
  }
  @media (prefers-color-scheme: dark) {
    background-color: $dark-bg;
  }
}
.fixed-images {
  position: relative;
}
.left-images {
  flex-grow: 1;
}
.right-images {
  flex-grow: 1;
}

.info {
  position: absolute;
  max-width: 87%;
  padding: 1rem 2rem;
  background-color: $fire-color;
  border-radius: 30px;
  height: 100%;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-color;

  a.call-to-action-link {
    color: $text-color;
    text-decoration: underline;
  }
}

.left-info-arrow {
  position: absolute;
  left: 80px;
  top: 96px;
}

.right-info-arrow {
  position: absolute;
  right: 80px;
  top: 96px;
  transform: scaleX(-1);
}

.film-strip {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 10px;
  min-height: 784px;
  padding-top: 125px;
}

.pair-container {
  height: 600px;
}
.pair-container .comparison-img-container:first-child {
  margin-right: 20px;
}

.film-strip > .media-pair:not(:last-child) {
  margin-bottom: 18px;
}

.media-pair {
  display: flex;
  flex-direction: row;
}

.film-strip .comparison-img-container {
  height: 100%;
  width: auto;
  cursor: pointer;
}

.media-overlay-container {
  position: relative;
  width: 300px;
  height: 300px;
}

.selected {
  border: 2px solid orange;
}
.not-selected {
  padding: 2px;
}

/* Narrow - need horizontal scroll - images stacked over under */
@media (orientation: portrait) {
  .info-images {
    flex-direction: column;
    position: sticky;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    max-width: unset;
    height: 100%;
    transform: unset;
    background-color: unset;
    pointer-events: none;
    z-index: 1;
  }
  .fixed-images {
    margin-bottom: unset;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 234px;
    width: 100dvw;
  }

  .left-images {
    flex-grow: 1;
  }
  .right-images {
    flex-grow: 1;
  }

  /* INFP */
  .info {
    position: relative;
    left: 0px;
    max-width: 360px;
    height: unset;
    bottom: unset;

    &.info-left {
      left: 0px;
      font-size: 1rem;
      line-height: 1.25rem;
    }

    &.info-right {
      font-size: 0.85rem;
      line-height: 0.9rem;
    }
  }

  /* ARROW */
  .left-info-arrow {
    position: relative;
    left: 20px;
    top: unset;
    bottom: 18px;
  }
  .right-info-arrow {
    position: relative;
    left: 20px;
    top: unset;
    bottom: 18px;
    transform: unset;
  }

  .film-strip {
    flex-direction: row;
    overflow-x: scroll;
    max-width: 100dvw;
    height: 100dvh;
    /* align-items: center; */
    padding: 0;
    overflow-y: clip;
  }
  .media-pair {
    position: relative;
    display: unset;
    height: 100%;
    max-width: calc(100dvh / 2);
  }
  .film-strip > .media-pair:not(:last-child) {
    margin-bottom: unset;
    margin-right: 20px;
  }
  .pair-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pair-container .comparison-img-container:first-child {
    margin-right: unset;
    margin-bottom: 8px;
  }

  .pair-container .comparison-img-container {
    display: flex;
    align-items: end;
  }

  .comparison-img {
    max-width: 300px;
    aspect-ratio: 1;
  }
}

/* Wide - needs vertical scroll - images stacked side-by-side */
@media (orientation: landscape) {
  .fixed-images {
    margin: 0.5rem;
  }

  @media (max-width: 767px) {
    .info {
      &.info-left {
        left: 18px;
        font-size: 1rem;
        line-height: 1.25rem;
      }

      &.info-right {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }

  @media (min-width: 768px) {
    .info {
      &.info-left {
        left: 18px;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      &.info-right {
        right: 64px;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }

  .film-strip {
    width: 100dvw;
  }
  .media-pair {
    width: 100%;
  }
  .pair-container {
    display: flex;
    height: unset;
    width: 100%;
  }
  .pair-container .comparison-img-container {
    height: unset;
    width: calc(calc(100% / 2) - 20px);
    aspect-ratio: 1;
  }
  .comparison-img {
    width: 100%;
    height: unset;
  }
  .media-overlay-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
