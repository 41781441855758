<script setup lang="ts">
import FilmStrip from "./FilmStrip.vue";
import ComparisonViewer from "./ComparisonViewer.vue";
import {
  computed,
  ComputedRef,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import api from "@/services/api";
import { ProcessingRunIdImageNumberResponse } from "../types/processingRunTypes";
import { MediaType, MediaItem } from "@/types/mediaTypes";

const route = useRoute();

//#region Image Viewing
const JPEG_IMAGES_IN_FILM_STRIP: number = 5;
const APNG_IMAGES_IN_FILM_STRIP: number = 1;
const ITEMS_IN_FILM_STRIP: number =
  JPEG_IMAGES_IN_FILM_STRIP + APNG_IMAGES_IN_FILM_STRIP;

const images1: Ref<Array<MediaItem | null>> = ref(
  Array(ITEMS_IN_FILM_STRIP).fill(null),
);
const images2: Ref<Array<MediaItem | null>> = ref(
  Array(ITEMS_IN_FILM_STRIP).fill(null),
);
const selectedImage1 = ref("");
const selectedImage2 = ref("");
const showComparisonViewer = ref(false);

const mediaPairs: ComputedRef<Array<Array<MediaItem | null>>> = computed(() => {
  const smallest = Math.min(images1.value.length, images2.value.length);
  const result = [];
  for (let i = 0; i < smallest; i++) {
    result.push([images1.value[i], images2.value[i]]);
  }
  return result;
});

const handleImageSelect = (stripRowIndex: number) => {
  currentMediaPairRowIndex.value = stripRowIndex;
  const image1Url = mediaPairs.value[stripRowIndex][1]?.src;
  const image2Url = mediaPairs.value[stripRowIndex][0]?.src;
  if (null != image1Url && null != image2Url) {
    selectedImage1.value = image1Url;
    selectedImage2.value = image2Url;
    if (selectedImage1.value && selectedImage2.value) {
      showComparisonViewer.value = true;
    }
  }
};

const closeComparisonViewer = () => {
  currentMediaPairRowIndex.value = null;
  showComparisonViewer.value = false;
  selectedImage1.value = "";
  selectedImage2.value = "";
};

const currentMediaPairRowIndex: Ref<number | null> = ref(null);

const handleNext = () => {
  const calculateIncrementedIndex = () => {
    if (null == currentMediaPairRowIndex.value) return 0;
    return (currentMediaPairRowIndex.value + 1) % mediaPairs.value.length;
  };

  currentMediaPairRowIndex.value = calculateIncrementedIndex();

  const image1Url = mediaPairs.value[currentMediaPairRowIndex.value][1]?.src;
  const image2Url = mediaPairs.value[currentMediaPairRowIndex.value][0]?.src;
  if (null != image1Url && null != image2Url) {
    selectedImage1.value = image1Url;
    selectedImage2.value = image2Url;
  }
};

const handlePrevious = () => {
  const calculateDecrementedIndex = () => {
    if (currentMediaPairRowIndex.value == 0) return mediaPairs.value.length - 1;
    if (null == currentMediaPairRowIndex.value) return 0;
    return currentMediaPairRowIndex.value - 1;
  };
  currentMediaPairRowIndex.value = calculateDecrementedIndex();

  const image1Url = mediaPairs.value[currentMediaPairRowIndex.value][1]?.src;
  const image2Url = mediaPairs.value[currentMediaPairRowIndex.value][0]?.src;
  if (null != image1Url && null != image2Url) {
    selectedImage1.value = image1Url;
    selectedImage2.value = image2Url;
  }
};
//#endregion

//#region Image Polling
const currentPollingImageNumber = ref(1);

/**
 * Query the JPEG image polling API endpoint.
 *
 * @param runId the run ID
 * @param imageNumber the JPEG image number to retrieve
 * @returns Promise<ProcessingRunIdImageNumberResponse | null>
 */
const queryImageJpegPollingEndpoint = async (
  runId: number,
  imageNumber: number,
): Promise<ProcessingRunIdImageNumberResponse | null> => {
  const response = await api.get<ProcessingRunIdImageNumberResponse>(
    `/processing-run/${runId}/image/${imageNumber}`,
  );
  const data = response.data;
  if (
    data.mitigated_progression_image != null &&
    data.progression_image != null
  ) {
    return data;
  }
  return null;
};

/**
 * Query the APNG image polling API endpoint.
 *
 * @param runId the run ID
 * @returns Promise<{ url: string } | null>
 */
const queryImageApngPollingEndpoint = async (
  runId: number,
): Promise<{ url: string } | null> => {
  const response = await api.get<{ url: string }>(
    `/processing-run/${runId}/video/4.apng`,
  );
  if (response.status === 404) {
    return null;
  } else {
    return response.data;
  }
};

/**
 * Poll for all the JPEG images associated with the given run ID. Polling is
 * done image-by-image in intervals of 3 seconds.
 *
 * @param runId the run ID
 */
const pollImagesJpeg = async (runId: number) => {
  if (currentPollingImageNumber.value <= JPEG_IMAGES_IN_FILM_STRIP) {
    try {
      const response = await queryImageJpegPollingEndpoint(
        runId,
        currentPollingImageNumber.value,
      );
      if (null != response) {
        console.log(
          `JPEG Image polling got a response (with runId: ${runId} and imageNumber: ${currentPollingImageNumber.value})`,
        );

        images1.value[currentPollingImageNumber.value - 1] = {
          src: `data:image/jpeg;base64,${response.progression_image}`,
          type: MediaType.ImageJPEG,
        };

        images2.value[currentPollingImageNumber.value - 1] = {
          src: `data:image/jpeg;base64,${response.mitigated_progression_image}`,
          type: MediaType.ImageJPEG,
        };

        currentPollingImageNumber.value++;
      } else {
        console.log(
          `JPEG Image polling response for runId: ${runId}, imageNumber: ${currentPollingImageNumber.value} was null, continuing to poll every 3 seconds...`,
        );
      }
      setTimeout(() => {
        pollImagesJpeg(runId);
      }, 3000);
    } catch (err: any) {
      console.log(`Error while polling for JPEG images: ${err.message}`);
    }
  } else {
    // Polling is complete.
    return;
  }
};
//#endregion

/**
 * Poll for the APNG image associated with the given run ID. Polling is done in
 * intervals of 3 seconds.
 *
 * @param runId the run ID
 */
const pollImageApng = async (runId: number) => {
  try {
    const response = await queryImageApngPollingEndpoint(runId);
    if (null != response) {
      console.log(`APNG image polling got a response (with runId: ${runId})`);
      images1.value[ITEMS_IN_FILM_STRIP - 1] = {
        src: response.url,
        type: MediaType.ImageAPNG,
      };
      // Polling is complete
      return;
    } else {
      console.log(
        `APNG image polling response for runId: ${runId} was null, continuing to poll every 3 seconds...`,
      );
      setTimeout(() => {
        pollImageApng(runId);
      }, 3000);
    }
  } catch (err: any) {
    console.log(
      `Error while polling for APNG image: ${err.message}. Continuing to poll every 3 seconds...`,
    );
    setTimeout(() => {
      pollImageApng(runId);
    }, 3000);
  }
};
//#endregion

/**
 * Begin polling for the all the JPEG and APNG images associated with the given
 * run ID.
 *
 * @param runId the run ID
 */
async function startPolling(runId: number) {
  pollImagesJpeg(runId);
  pollImageApng(runId);
}

//#region Lifecycle hooks
onMounted(async () => {
  console.log(`Got runId: ${route.params.uuid}`);

  // Preset values for the last pair of slots in the film strip because the
  // APNG image wil be queried for immediately instead of in succession like
  // the JPEG images. So, we want the UI to evaluate immediately.
  images1.value[ITEMS_IN_FILM_STRIP - 1] = {
    src: "",
    type: MediaType.ImageAPNG,
  };
  images2.value[ITEMS_IN_FILM_STRIP - 1] = {
    src: "",
    type: null,
  };

  if (typeof route.params.uuid == "string") {
    const uuidNum = Number(route.params.uuid);
    startPolling(uuidNum);
  }
});

onBeforeUnmount(() => {});
//#endregion
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col class="d-flex">
        <FilmStrip
          :mediaPairs="mediaPairs"
          :comparison-viewer-selected-row-index="currentMediaPairRowIndex"
          @select-media-pair="(rowIndex) => handleImageSelect(rowIndex)"
          :comparison-viewer-open="showComparisonViewer"
        />
        <!-- JIA TODO: Replace this with a carousel -->
        <ComparisonViewer
          v-if="showComparisonViewer"
          :image1="selectedImage1"
          :image2="selectedImage2"
          :allImages1="images1"
          :allImages2="images2"
          @close="closeComparisonViewer"
          @previous="handlePrevious"
          @next="handleNext"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
</style>
