export enum MediaType {
  ImageJPEG = "image/jpeg",
  ImageAPNG = "image/apng",
  VideoMP4 = "video/mp4",
}

export type ImageMediaItem = {
  type: MediaType.ImageJPEG | MediaType.ImageAPNG | null;
  src: string;
};

export type VideoMediaItem = {
  type: MediaType.VideoMP4 | null;
  src: string;
};

export type MediaItem = ImageMediaItem | VideoMediaItem;
