<script setup lang="ts">
import { defineEmits } from "vue";

const emit = defineEmits(["previous", "next"]);
</script>

<template>
  <div class="navigation-arrows">
    <button class="arrow left" @click="emit('previous')">&lt;</button>
    <button class="arrow right" @click="emit('next')">&gt;</button>
  </div>
</template>

<style scoped>
.navigation-arrows {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.arrow {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
