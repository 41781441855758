<script setup lang="ts">
import { defineProps, ref } from "vue";

interface Props {
  image1: string;
  image2: string;
}

const props = defineProps<Props>();
const sliderPosition = ref(50);

const updateSliderPosition = (event: Event) => {
  const target = event.target as HTMLInputElement;
  sliderPosition.value = parseInt(target.value);
};
</script>

<template>
  <div class="image-slider">
    <div class="image-container">
      <img :src="props.image1" alt="Image 1" class="image image-1" />
      <img
        :src="props.image2"
        alt="Image 2"
        class="image image-2"
        :style="{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }"
      />
    </div>
    <input
      type="range"
      min="0"
      max="100"
      v-model="sliderPosition"
      @input="updateSliderPosition"
      class="slider"
    />
  </div>
</template>

<style scoped>
.image-slider {
  position: relative;
  width: 80vw;
  height: 80vh;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-2 {
  clip-path: inset(0 50% 0 0);
}

.slider {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}
</style>
