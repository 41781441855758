<template>
  <div class="address-form m-5">
    <h2 class="mb-4 fw-bold">Address Lookup and Street View</h2>
    <form @submit.prevent="lookupAddress">
      <div>
        <label for="street-address">Street Address:</label>
        <input id="street-address" v-model="streetAddress" required />
      </div>
      <div>
        <label for="zip-code">ZIP Code:</label>
        <input id="zip-code" v-model="zipCode" required />
      </div>
      <button class="btn btn-primary" type="submit">Lookup Address</button>
    </form>

    <div v-show="addressConfirmed">
      <h3 class="mt-5 mb-4">Confirmed Address:</h3>
      <p>{{ confirmedAddress }}</p>
      <div id="street-view" class="street-view" v-show="showStreetView"></div>
      <div v-show="showStreetView">
        <div v-if="route.name === 'WindSim'" class="my-4">
          <b-form-group>
            <b-form-radio-group
              v-model="damageType"
              buttons
              button-variant="primary"
            >
              <b-form-radio :value="DamageType.Tornado">Tornado</b-form-radio>
              <b-form-radio :value="DamageType.Hurricane"
                >Hurricane</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <label for="heading">Heading: </label>
        <input id="heading" v-model.number="heading" type="number" disabled />
        <label for="pitch">Pitch: </label>
        <input id="pitch" v-model.number="pitch" type="number" disabled />
        <label for="zoom">Zoom: </label>
        <input id="zoom" v-model.number="zoom" type="number" disabled />
      </div>
      <button
        class="btn btn-primary mt-3"
        @click="postToSubmitStreetView"
        v-show="showStreetView"
      >
        <template v-if="!loading">Submit Street View</template>
        <template v-else>Submitting...</template>
      </button>
      <label v-show="submittedImage">Submitted Street View image:</label>
      <img
        v-if="submittedImage"
        :src="submittedImage"
        alt="Submitted Street View"
        class="submitted-image"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, Ref } from "vue";
import { storeToRefs } from "pinia";
import api from "@/services/api";
import { useRouter, useRoute } from "vue-router";
import { DamageCategory, DamageType } from "@/types/simTypes";
import { useDamageStore } from "@/stores/damage";

declare global {
  interface Window {
    initStreetView: () => void;
    google: any;
  }
}

export default defineComponent({
  name: "AddressForm",
  setup() {
    const streetAddress = ref("");
    const zipCode = ref("");
    const confirmedAddress = ref("");
    const addressConfirmed = ref(false);
    const latitude = ref(0);
    const longitude = ref(0);
    const heading = ref(0);
    const pitch = ref(0);
    const zoom = ref(1);
    const submittedImage = ref("");
    const showStreetView = ref(false);
    const loading: Ref<boolean> = ref(false);
    let panorama: google.maps.StreetViewPanorama | null = null;
    const router = useRouter();
    const route = useRoute();
    const damageStore = useDamageStore();
    const { damageType, damageCategory } = storeToRefs(damageStore);

    const lookupAddress = async () => {
      try {
        const response = await api.post("/lookup-address", {
          street_address: streetAddress.value,
          zip_code: zipCode.value,
        });
        confirmedAddress.value = response.data.formatted_address;
        latitude.value = response.data.latitude;
        longitude.value = response.data.longitude;
        addressConfirmed.value = true;
        showStreetView.value = true;
        initStreetView();
      } catch (error) {
        console.error("Error looking up address:", error);
        alert("Error looking up address. Please try again.");
      }
    };

    const initStreetView = () => {
      const streetViewDiv = document.getElementById("street-view");
      if (streetViewDiv && window.google && window.google.maps) {
        panorama = new window.google.maps.StreetViewPanorama(streetViewDiv, {
          position: { lat: latitude.value, lng: longitude.value },
          pov: { heading: heading.value, pitch: pitch.value },
          zoom: zoom.value,
        });

        panorama!.addListener("pov_changed", () => {
          const pov = panorama!.getPov();
          heading.value = pov.heading;
          pitch.value = pov.pitch;
        });

        panorama!.addListener("zoom_changed", () => {
          zoom.value = panorama!.getZoom();
        });
      }
    };

    const postToSubmitStreetView = async () => {
      if (!panorama) {
        console.error("Street View panorama not initialized");
        return;
      }

      try {
        loading.value = true;
        const position = panorama.getPosition();
        const response = await api.post("/submit-streetview", {
          latitude: position?.lat(),
          longitude: position?.lng(),
          heading: heading.value,
          pitch: pitch.value,
          fov: 180 / Math.pow(2, zoom.value),
          damage_type: damageType.value,
        });
        submittedImage.value = `data:image/jpeg;base64,${response.data.image}`;
        showStreetView.value = false;
        router.push({
          name:
            damageCategory.value === DamageCategory.Fire
              ? "FireResultsPage"
              : damageCategory.value === DamageCategory.Wind
                ? "WindResultsPage"
                : "",
          params: { uuid: response.data.run_id },
        });
      } catch (error) {
        console.error("Error submitting street view:", error);
        alert("Error submitting street view. Please try again.");
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      window.initStreetView = initStreetView;
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBNx9avKFccciEomb7de2X2ZcvytnV3tPI&callback=initStreetView`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      damageType.value =
        route.name === "FireSim"
          ? DamageType.Fire
          : route.name === "WindSim"
            ? DamageType.Tornado
            : DamageType.Fire;
    });

    return {
      streetAddress,
      zipCode,
      confirmedAddress,
      addressConfirmed,
      heading,
      pitch,
      zoom,
      submittedImage,
      damageType,
      DamageType,
      lookupAddress,
      postToSubmitStreetView,
      showStreetView,
      initStreetView,
      loading,
      route,
    };
  },
});
</script>

<style scoped>
.address-form {
  max-width: 600px;
  margin: 0 auto;
}

form div,
.address-form > div {
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  margin-right: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

input[type="number"] {
  width: auto;
}

.street-view {
  width: 100%;
  max-width: 640px;
  height: 0;
  padding-bottom: 56.25%;
  margin: 1rem auto;
  position: relative;
}

.street-view iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.submitted-image {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
}

.active {
  /* Additional active styles if needed */
}
</style>
