import { createRouter, createWebHistory } from "vue-router";
import { storeToRefs } from "pinia";
import { DamageCategory } from "@/types/simTypes";
import { useDamageStore } from "@/stores/damage";
import HelloWorld from "../components/HelloWorld.vue";
import ResultsPage from "@/components/ResultsPage.vue";

const routes = [
  {
    path: "/fire",
    name: "FireSim",
    component: HelloWorld,
  },
  {
    path: "/wind",
    name: "WindSim",
    component: HelloWorld,
  },
  {
    path: "/fire/results/:uuid",
    name: "FireResultsPage",
    component: ResultsPage,
  },
  {
    path: "/wind/results/:uuid",
    name: "WindResultsPage",
    component: ResultsPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  const damageStore = useDamageStore();
  const { damageCategory } = storeToRefs(damageStore);

  if (to.path.startsWith("/fire")) {
    damageCategory.value = DamageCategory.Fire;
  } else if (to.path.startsWith("/wind")) {
    damageCategory.value = DamageCategory.Wind;
  } else {
    damageCategory.value = null;
  }
  next();
});

export default router;
