export enum DamageType {
  Fire = "fire",
  Hurricane = "hurricane",
  Tornado = "tornado",
}

export enum DamageCategory {
  Fire = "fire",
  Wind = "wind",
}
