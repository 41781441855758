import { createApp } from "vue";
import { createBootstrap } from "bootstrap-vue-next";
import "@/assets/scss/styles.scss";
import App from "./App.vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import axios from "axios";
import router from "./router";

const app = createApp(App);

const isDevelopment = import.meta.env.VITE_ENV === "development";
if (isDevelopment) {
  console.log("Running in development mode");
} else {
  console.log("Running in production mode");
}

app.use(createBootstrap());

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.use(router);

// Setup axios to use a base URL of the API from the VITE_API_URL environment variable
// or default to http://localhost:8001
axios.defaults.baseURL =
  import.meta.env.VITE_API_URL || "http://localhost:8001";

app.mount("#app");
