<script setup lang="ts">
import { MediaItem } from "@/types/mediaTypes";
import { defineProps, defineEmits } from "vue";
import ImageSlider from "./ImageSlider.vue";
import NavigationArrows from "./NavigationArrows.vue";

interface Props {
  image1: string;
  image2: string;
  allImages1: Array<MediaItem | null>;
  allImages2: Array<MediaItem | null>;
}

const props = defineProps<Props>();
const emit = defineEmits(["close", "previous", "next"]);

const handlePrevious = () => {
  //Emit the 'previous' event
  emit("previous");
};

const handleNext = () => {
  // Emit the 'next' event
  emit("next");
};
</script>

<template>
  <div class="comparison-viewer z-2">
    <div class="underlay" @click="emit('close')"></div>
    <button class="close-button" @click="emit('close')">Close</button>
    <ImageSlider :image1="props.image1" :image2="props.image2" />
    <NavigationArrows @previous="handlePrevious" @next="handleNext" />
  </div>
</template>

<style scoped>
.comparison-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.underlay {
  position: absolute;
  width: 100%;
  height: 100dvh;
}
</style>
