<template>
  <div>
    <AddressForm></AddressForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AddressForm from "./AddressForm.vue";

export default defineComponent({
  name: "EntryComponent",
  components: {
    AddressForm,
  },
  setup() {
    return;
  },
});
</script>
